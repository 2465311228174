import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout backButton={true} pageInfo={{ pageName: "not-found" }}>
    <div
      style={{
        minHeight: `50vh`,
      }}
    >
      <Seo title="404: Not found" />
      <h1
        style={{
          marginBottom: `2rem`,
        }}
      >
        Страницата не е открита
      </h1>
      <p>
        Можете да се върнете към началната страница от <Link to="/">тук</Link>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
